import ajax from './common/ajax'
import googleAds from './ads/google'

window.newsletter_popup = function () {
  function initContactForm () {
    $('div.wpcf7 > form').each(function () {
      var $form = $(this)
      wpcf7.initForm($form)
      if (wpcf7.cached) {
        wpcf7.refill($form)
      }
    })
  }
  initContactForm()

  let popupClosers = document.querySelectorAll('[data-close-newsletter-popup]')
  /*
  for(var i=0; i < popupClosers.length; i++) {
    console.log (popupClosers[i]);
  }
  */
  popupClosers.forEach(popupCloser => {
    popupCloser.addEventListener('click', () => {
      const targetSelector = popupCloser.getAttribute('data-close-newsletter-popup')
      const target = document.querySelector(targetSelector)
      target.style.display = 'none'

      ajax('/wp-json/ads/v1/newsletter_popup_close', (data) => {
        data = JSON.parse(data)
        // console.log('newsletter_popup_close', data)
      })
    })
  })
}

window.newsletter_article_sidebar = function () {
  return

    // assume jquery is loaded
  let element, elementHeight, nextElementTop, doc = $(document)

  var newsFixedEl = $('[data-newsletter-side-fixed]')

  if (newsFixedEl.length > 0) {
    newsFixedEl.css({'top': 0})
    $(window).scroll(() => {
      $('[data-newsletter-side-fixed]').each(function () {
        element = $(this)
        elementHeight = element.outerHeight()
        nextElementTop = element.parent().nextAll('div').eq(0).position().top
        element.css({'top': Math.min(nextElementTop - elementHeight, doc.scrollTop())}) // it's this simple because we know it is the first element of its parent
        // console.log(elementHeight, doc.scrollTop(), nextElementTop)
      })
    })
  }/* .click(function () {
    let element = $(this);
    $(element.data("newsletter-bar-toggler")).toggleClass(element.data("newsletter-bar-toggler-class"))
  }) */
}

window.newsletter_mobile_fixed_bar = function () {
  // jQuery('#ajaxform_wrapper > div.wpcf7 > form').wpcf7InitForm();
  $('body').addClass('newsletter_mobile_fixed_bar')
  $('.single-post .newsletter-article-mobile').slideUp()
  $('.single-post .newsletter-footer-mobile').slideUp()
  $('[data-newsletter-bar-toggler]').click(function () {
    let element = $(this)
    $(element.data('newsletter-bar-toggler')).toggleClass(element.data('newsletter-bar-toggler-class'))
  })
}

window.newsletter_intra_posts_removal = function () {
  $('.newsletter__intra-posts-container > div, .newsletter-homepage > div').hide()
}

window.setup_cookie_message = function () {
  !window.sv.setupCookieConsent || window.sv.setupCookieConsent()
}

// setup google ads manager
googleAds.setupInterface()
googleAds.setupElements()
