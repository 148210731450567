import passive from '../utils/passiveListeners'

const adSlots = {}
let mappings = {}

function setupInterface () {
	/**
   * the google tag services script (www.googletagservices.com/tag/js/gpt.js) should be added through the page header
	 */
  if (window.googletag) {
    googletag.cmd.push(function () {
      mappings.map_desktop_top = googletag.sizeMapping().addSize([1, 1]).build()
      mappings.map_desktop = googletag.sizeMapping().addSize([0, 0], [[728, 90], [970, 90], [970, 250]]).build()
      mappings.map_desktop_horizontal = googletag.sizeMapping().addSize([0, 0], [[728, 90], [970, 90]]).build()
      mappings.map_single = googletag.sizeMapping().addSize([1, 1], [[300, 250], [300, 600]]).build()
      mappings.map_desktop_large = googletag.sizeMapping().addSize([0, 0], [[728, 90], [970, 90], [970, 250]]).build()
      mappings.map_mobile = googletag.sizeMapping().addSize([0, 0], [[300, 250], [300, 600], [480, 300]]).build()

      // New V3 mappings
      mappings.map_article_header_desktop = googletag.sizeMapping().addSize([0, 0], [[728, 90], [970, 90], [970, 250]]).build()
      mappings.map_article_header_mobile = googletag.sizeMapping().addSize([0, 0], [[300, 250], [300, 600], [480, 300]]).build()

      mappings.map_article_aside = googletag.sizeMapping().addSize([0, 0], [[300, 250], [300, 600]]).build()
      mappings.map_article_aside_limited_350 = googletag.sizeMapping().addSize([0, 0], [[300, 250]]).build()

      mappings.map_article_bottom_desktop = googletag.sizeMapping().addSize([0, 0], [[728, 90], [970, 90], [970, 250]]).build()
      mappings.map_article_bottom_mobile = googletag.sizeMapping().addSize([0, 0], [[300, 250], [480, 300]]).build()

      mappings.map_article_middle_desktop = googletag.sizeMapping().addSize([0, 0], [[300, 250], [300, 600]]).build()
      mappings.map_article_middle_mobile = googletag.sizeMapping().addSize([0, 0], [[300, 250], [480, 300]]).build()

      googletag.pubads().enableSingleRequest()
      googletag.pubads().collapseEmptyDivs()
      googletag.enableServices()
    })
  }
}

// call this one on all ajax calls
function setupElements () {
  // console.log ("google", "setup elements")
  var elements = document.querySelectorAll('[data-google-ad-slot]')
  var elem
  for (var i = 0; i < elements.length; i++) {
    elem = elements[i]
    if (!elem.classList.contains('google-ad-printed') && !elem.classList.contains('ad--prevent-autoload')) {
      // console.log ("google", "call element fill", elem)
      fillElement(elem, elem.dataset.googleAdSlot, elem.dataset.googleAdSize.split(',').map(i => parseInt(i)), elem.dataset.googleAdMapping, {
        'POS': [elem.dataset.googleAdPos],
        'DEV': [elem.dataset.googleAdDev],
        'LOC': [elem.dataset.googleAdLoc],
        'ENV': [document.querySelector('body').dataset.env]
      })
      elem.classList.add('google-ad-printed')
    }
  }
}

/**
 * Initialize listeners
 */
function fillElement (element, slotCode, size, mapping, targeting) {
  if ('IntersectionObserver' in window) {
    let intersectionObserver = new IntersectionObserver(function (entries, observer) {
      var entry
      for (var i = 0; i < entries.length; i++) {
        entry = entries[i]
        if (entry.isIntersecting) {
          doFill(element, slotCode, size, mapping, targeting)
          intersectionObserver.unobserve(entry.target)
        }
      }
    })
    intersectionObserver.observe(element)
  } else {
    if (inViewportLegacy(element)) {
      doFill(element, slotCode, size, mapping, targeting)
    }
		// Fallback for legacy browsers
    var events = ['scroll', 'resize']
    var dispatcher
    for (var i = 0; i < events.length; i++) {
      dispatcher = events[i]
      window.addEventListener(dispatcher, () => {
        if (inViewportLegacy(element)) {
          doFill(element, slotCode, size, mapping, targeting)
        }
      }, passive() ? { passive: true } : false)
    }
  }
}

/**
 * Check if element is in viewport (legacy browsers)
 *
 * @param el
 */
function inViewportLegacy (el) {
  if (!el.classList.contains('ad-filled') && el.offsetParent !== null) {
    let rect = el.getBoundingClientRect()
    if (rect.bottom >= 0 && rect.right >= 0 && rect.top <= (window.innerHeight || document.documentElement.clientHeight) && rect.left <= (window.innerWidth || document.documentElement.clientWidth)) {
      el.classList.add('ad-filled')
      return true
    }
  }
}

window.googleAdFill = function (element) {
  console.log('googleAdFill', element.classList.contains('google-ad-printed'))
  if (!element.classList.contains('google-ad-printed')) {
    doFill(
      element,
      element.dataset.googleAdSlot,
      element.dataset.googleAdSize.split(',').map(i => parseInt(i)),
      element.dataset.googleAdMapping,
      {
        'POS': [element.dataset.googleAdPos],
        'DEV': [element.dataset.googleAdDev],
        'LOC': [element.dataset.googleAdLoc],
        'ENV': [document.querySelector('body').dataset.env]
      }
    )
    element.classList.add('google-ad-printed')
  }
}

/**
 * Fill Element with Ad
 *
 * @param element
 * @param slotCode
 * @param size
 * @param mapping
 * @param targeting
 */
function doFill (element, slotCode, size, mapping, targeting) {
  if (typeof targeting === 'undefined') {
    targeting = {}
  } else if (Object.prototype.toString.call(targeting) !== '[object Object]') {
    targeting = {}
  }

  googletag.cmd.push(function () {
    console.log('google', slotCode, size, element.id, targeting, mappings[mapping])
    var slot = googletag.defineSlot(slotCode, size, element.id)
    for (var t in targeting) {
      // console.log(t, targeting[t])
      slot.setTargeting(t, targeting[t])
    }
    if (mapping && mappings[mapping]) {
      slot.defineSizeMapping(mappings[mapping])
    }
    slot.addService(googletag.pubads())
    googletag.display(element.id)
    // console.log ("google", "display this one", element.id);
  })
}

const googleAds = {
  setupInterface,
  setupElements
}

export default googleAds
