/**
 * Test for passive listeners support
 *
 * @returns {boolean}
 */
export default function passive () {
  let supportsPassive = false
  document.createElement('div').addEventListener('test', _ => {}, {
    get passive () {
      supportsPassive = true
    }
  })

  return supportsPassive
}
